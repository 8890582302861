import * as React from "react";
import { useState, useEffect } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../firebase";
import { getSession } from "../session";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Alert from "@mui/material/Alert";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import EventIcon from "@mui/icons-material/Event";
import FlightIcon from "@mui/icons-material/Flight";
import LockIcon from "@mui/icons-material/Lock";
import HomeIcon from "@mui/icons-material/Home";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import CircularProgress from "@mui/material/CircularProgress";
import { Container, AppBar, Toolbar, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSubscriptionContext } from "./SubscriptionContext";

// Estados de suscripción
const SUBSCRIPTION_STATUS = {
  NONE: "NONE",
  ACTIVE: "ACTIVE",
  CANCELLED: "CANCELLED",
  EXPIRED: "EXPIRED",
};

// Configuración de planes
const plans = {
  free: [
    {
      name: "Free",
      price: 0,
      plan_id: "free-plan",
      features: [
        "3 publicación de ofertas por mes",
        "2 publicación de eventos por mes",
        "No incluye publicaciones de viajes",
        "No incluye publicaciones de autos",
      ],
    }
  ],
  mensual: [
    {
      name: "Básico",
      price: 29.99,
      plan_id: "P-9KR91446SP2985843M4MTBBA",
      features: [
        "3 publicaciones de ofertas por mes",
        "2 publicaciones de eventos por mes",
        "1 publicación de viajes por mes",
        "1 publicación de autos por mes",
      ],
    },
    {
      name: "Profesional",
      price: 59.99,
      plan_id: "P-3TW91102EV019554FM4MTCYY",
      features: [
        "10 publicaciones de ofertas por mes",
        "5 publicaciones de eventos por mes",
        "3 publicaciones de viajes por mes",
        "3 publicaciones de autos por mes",
      ],
    },
    {
      name: "Premium",
      price: 99.99,
      plan_id: "P-4RG646479D367172TM4MTDYY",
      features: [
        "Publicaciones ilimitadas de ofertas",
        "15 publicaciones de eventos por mes",
        "10 publicaciones de viajes por mes",
        "10 publicaciones de autos por mes",
      ],
    },
  ],
  anual: [
    {
      name: "Básico",
      price: 299.99,
      plan_id: "P-82A32109SS163581PM4NJT7A",
      features: [
        "3 publicaciones de ofertas por mes",
        "2 publicaciones de eventos por mes",
        "1 publicación de viajes por mes",
        "1 publicación de autos por mes",
      ],
    },
    {
      name: "Profesional",
      price: 599.99,
      plan_id: "P-4LP05059M5462104JM4NJTOI",
      features: [
        "10 publicaciones de ofertas por mes",
        "5 publicaciones de eventos por mes",
        "3 publicaciones de viajes por mes",
        "3 publicaciones de autos por mes",
      ],
    },
    {
      name: "Premium",
      price: 999.99,
      plan_id: "P-5KK71784ME571452TM4NJSSY",
      features: [
        "Publicaciones ilimitadas de ofertas",
        "15 publicaciones de eventos por mes",
        "10 publicaciones de viajes por mes",
        "10 publicaciones de autos por mes",
      ],
    },
  ],
};

// Tema personalizado
const theme = createTheme({
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          textTransform: "none",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 12,
        },
      },
    },
  },
});

// Componente para iconos de categoría
const CategoryIcon = ({ category }) => {
  switch (category) {
    case "ofertas":
      return <LocalOfferIcon sx={{ color: "success.main" }} />;
    case "eventos":
      return <EventIcon sx={{ color: "primary.main" }} />;
    case "viajes":
      return <FlightIcon sx={{ color: "info.main" }} />;
    case "autos":
      return <DirectionsCarIcon sx={{ color: "warning.main" }} />;
    default:
      return <CheckCircleOutlineIcon color="success" />;
  }
};
const useAuth = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initAuth = async () => {
      try {
        const session = await getSession();
        setUser(session);
      } catch (error) {
        console.error("Error getting session:", error);
      } finally {
        setLoading(false);
      }
    };

    initAuth();
  }, []);

  return { user, loading };
};

// Componente de selección de plan
const PlanSelection = ({
  selectedPlan,
  setSelectedPlan,
  billingInterval,
  setBillingInterval,
}) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { setSubscription } = useSubscriptionContext();

  const activateFreePlan = async () => {
    try {
      const freePlan = plans.free[0];
      const subscriptionData = {
        status: SUBSCRIPTION_STATUS.ACTIVE,
        subscriptionId: 'free',
        planId: freePlan.plan_id,
        planName: freePlan.name,
        price: freePlan.price,
        interval: 'free',
        startDate: new Date().toISOString(),
        nextBillingDate: null,
        lastUpdated: new Date().toISOString(),
      };

      await updateDoc(doc(db, "users", user.uid), {
        subscription: subscriptionData,
      });

      setSubscription(subscriptionData);
      navigate("/subscription");
    } catch (error) {
      console.error("Error activating free plan:", error);
    }
  };
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ display: "flex", justifyContent: "center", mb: 4 }}>
        <ToggleButtonGroup
          value={billingInterval}
          exclusive
          onChange={(_, newInterval) =>
            newInterval && setBillingInterval(newInterval)
          }
          aria-label="intervalo de facturación"
        >
          <ToggleButton value="mensual">Mensual</ToggleButton>
          <ToggleButton value="anual">
            Anual
            <Typography
              component="span"
              sx={{
                ml: 1,
                px: 1,
                py: 0.5,
                bgcolor: "success.light",
                color: "success.contrastText",
                borderRadius: "pill",
                fontSize: "0.75rem",
              }}
            >
              Ahorra 20%
            </Typography>
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>

      <Grid container spacing={3}>
        {plans[billingInterval].map((plan) => (
          <Grid item xs={12} md={4} key={plan.name}>
            <Card
              onClick={() => setSelectedPlan(plan)}
              sx={{
                cursor: "pointer",
                height: "100%",
                transition: "all 0.2s",
                border: selectedPlan?.name === plan.name ? 2 : 1,
                borderColor:
                  selectedPlan?.name === plan.name ? "primary.main" : "divider",
                "&:hover": {
                  borderColor: "primary.main",
                  boxShadow: 2,
                },
              }}
            >
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Plan {plan.name}
                </Typography>
                <Typography variant="h4" component="div" gutterBottom>
                  ${plan.price}
                  <Typography
                    component="span"
                    variant="body2"
                    color="text.secondary"
                    sx={{ ml: 1 }}
                  >
                    /{billingInterval === "mensual" ? "mes" : "año"}
                  </Typography>
                </Typography>
                <Stack spacing={1.5} sx={{ mt: 2 }}>
                  {plan.features.map((feature) => {
                    const category = feature.includes("ofertas")
                      ? "ofertas"
                      : feature.includes("eventos")
                      ? "eventos"
                      : feature.includes("viajes")
                      ? "viajes"
                      : feature.includes("autos")
                      ? "autos"
                      : "general";

                    return (
                      <Box
                        key={feature}
                        sx={{ display: "flex", alignItems: "center", gap: 1 }}
                      >
                        <CategoryIcon category={category} />
                        <Typography variant="body2">{feature}</Typography>
                      </Box>
                    );
                  })}
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mt: 6,
          mb: 10,
          gap: 1,
        }}
      >
        <Typography color="text.secondary">
          ¡Bienvenido, empecemos a publicar!
        </Typography>
        <Button
          onClick={activateFreePlan}
          variant="text"
          sx={{
            color: "primary.main",
            "&:hover": {
              backgroundColor: "rgba(49, 39, 131, 0.04)",
            },
          }}
        >
          Continuar gratis como usuario
        </Button>
      </Box>
    </Box>
  );
};

// Componente de método de pago
const PaymentMethod = ({
  selectedPlan,
  billingInterval,
  onPaymentComplete,
}) => {
  // Agregamos billingInterval como prop
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [paypalKey, setPaypalKey] = useState(0);
  const { user } = useAuth(); // Obtenemos user del hook useAuth

  useEffect(() => {
    setPaypalKey((prev) => prev + 1);
  }, [selectedPlan]);

  const paypalOptions = {
    "client-id":
      "AaekkBNxAGaolaupCFGb84b8prIKjb4oIoRLjlWLVDkqKcOpP55OX6y444pq_ZO4JU7E4c1SiA0xXqfp",
    currency: "USD",
    intent: "subscription",
    vault: true,
    "data-sdk-integration-source": "button-factory",
  };

  const handleCreateSubscription = async (data, actions) => {
    try {
      setLoading(true);

      if (!selectedPlan?.plan_id) {
        throw new Error("Plan ID no disponible");
      }

      return actions.subscription.create({
        plan_id: selectedPlan.plan_id,
        application_context: {
          shipping_preference: "NO_SHIPPING",
          user_action: "SUBSCRIBE_NOW",
          brand_name: "Notiline",
          locale: "es-ES",
        },
      });
    } catch (err) {
      console.error("Error al crear suscripción:", err);
      setError(`Error al crear la suscripción: ${err.message}`);
      throw err;
    } finally {
      setLoading(false);
    }
  };

  const handleApprove = async (data, actions) => {
    if (!user) {
      setError("Usuario no autenticado");
      return;
    }

    try {
      setLoading(true);
      const subscriptionDetails = await actions.subscription.get();

      const subscriptionData = {
        status: subscriptionDetails.status,
        subscriptionId: subscriptionDetails.id,
        planId: selectedPlan.plan_id,
        planName: selectedPlan.name,
        price: selectedPlan.price,
        interval: billingInterval,
        startDate: new Date().toISOString(),
        nextBillingDate: subscriptionDetails.billing_info.next_billing_time,
        lastUpdated: new Date().toISOString(),
      };

      await updateDoc(doc(db, "users", user.uid), {
        subscription: subscriptionData,
      });

      onPaymentComplete(subscriptionData);
    } catch (err) {
      setError("Error al procesar el pago. Por favor, contacte a soporte.");
      console.error("Error detallado:", err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container maxWidth="lg" sx={{ mb: 10 }}>
      <PayPalScriptProvider options={paypalOptions}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          {error && (
            <Alert
              severity="error"
              onClose={() => setError(null)}
              sx={{ mb: 3, width: "100%" }}
            >
              {error}
            </Alert>
          )}

          <Box sx={{ width: "100%", mb: 3 }}>
            <Box
              sx={{
                p: 3,
                border: 1,
                borderColor: "divider",
                borderRadius: 1,
                backgroundColor: "background.paper",
                position: "relative",
                display: "flex",
                justifyContent: "center",
                "& .paypal-buttons": {
                  maxWidth: "500px",
                  minWidth: "250px",
                  width: "100% !important",
                },
              }}
            >
              {loading && (
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "rgba(255, 255, 255, 0.7)",
                    zIndex: 1,
                  }}
                >
                  <CircularProgress />
                </Box>
              )}

              {/* Añadimos key al PayPalButtons para forzar su reinicio */}
              <PayPalButtons
                key={paypalKey}
                style={{
                  shape: "rect",
                  color: "blue",
                  layout: "vertical",
                  label: "subscribe",
                }}
                createSubscription={handleCreateSubscription}
                onApprove={handleApprove}
                onError={(err) => setError("Ocurrió un error con PayPal.")}
                onCancel={() => setLoading(false)}
              />
            </Box>

            <Alert severity="info" sx={{ mt: 3, width: "100%" }}>
              No necesitas tener una cuenta de PayPal - puedes pagar con tarjeta
            </Alert>
          </Box>

          <Alert severity="success" icon={<LockIcon />} sx={{ width: "100%" }}>
            Pago seguro procesado por PayPal
          </Alert>
        </Box>
      </PayPalScriptProvider>
    </Container>
  );
};

const SubscriptionStatus = ({ subscriptionInfo, onCancelSubscription }) => {
  const [currentStatus, setCurrentStatus] = useState(subscriptionInfo.status);
  const [isUpdating, setIsUpdating] = useState(false);
  const [error, setError] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { user } = useAuth();

  // Get the current plan features
  const getPlanFeatures = () => {
    if (subscriptionInfo.interval === 'free') {
      return plans.free[0].features;
    }
    return plans[subscriptionInfo.interval]?.find(
      plan => plan.name === subscriptionInfo.planName
    )?.features || [];
  };

  const handleCancelSubscription = async () => {
    if (!user?.uid || !subscriptionInfo.subscriptionId) return;
    setIsUpdating(true);
    setError(null);

    try {
      // Primero actualizar en Firebase
      await updateDoc(doc(db, "users", user.uid), {
        "subscription.status": "CANCELLED",
        "subscription.lastUpdated": new Date().toISOString(),
        "subscription.cancelDate": new Date().toISOString(),
      });

      setCurrentStatus("CANCELLED");
      onCancelSubscription && onCancelSubscription();

      // Redireccionar a PayPal para cancelación manual
      window.open("https://www.paypal.com/myaccount/autopay", "_blank");

      setShowConfirmation(false);
    } catch (error) {
      console.error("Error canceling subscription:", error);
      setError(
        "Error al cancelar la suscripción. Por favor intente nuevamente."
      );
    } finally {
      setIsUpdating(false);
    }
  };

  // Helper functions remain the same...
  const getStatusDisplay = (status) => {
    const statusMap = {
      ACTIVE: { text: "Activa", color: "success.main" },
      EXPIRED: { text: "Expirada", color: "error.main" },
      CANCELLED: { text: "Cancelada", color: "error.main" },
      SUSPENDED: { text: "Suspendida", color: "warning.main" },
    };
    return statusMap[status] || { text: status, color: "text.primary" };
  };

  const getDaysRemaining = () => {
    if (!subscriptionInfo.nextBillingDate) return null;
    const nextBilling = new Date(subscriptionInfo.nextBillingDate);
    const now = new Date();
    const diffTime = nextBilling - now;
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  const statusDisplay = getStatusDisplay(currentStatus);
  const daysRemaining = getDaysRemaining();

  return (
    <Box sx={{ maxWidth: 600, mx: "auto", p: 3 }}>
      <Card>
        <CardContent>
          <Stack spacing={3}>
            <Box sx={{ textAlign: "center" }}>
              <CheckCircleOutlineIcon
                sx={{ fontSize: 64, color: statusDisplay.color, mb: 2 }}
              />
              <Typography variant="h5" gutterBottom>
                Suscripción {statusDisplay.text}
              </Typography>
            </Box>

            <Box>
              <Typography variant="subtitle1" gutterBottom>
                Detalles de la suscripción:
              </Typography>
              <Stack spacing={1}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography>Plan:</Typography>
                  <Typography>{subscriptionInfo.planName}</Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography>Precio:</Typography>
                  <Typography>
                    ${subscriptionInfo.price}
                    {subscriptionInfo.interval !== 'free' && 
                      `/${subscriptionInfo.interval}`}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography>Estado:</Typography>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Typography color={statusDisplay.color}>
                      {statusDisplay.text}
                    </Typography>
                    {isUpdating && (
                      <CircularProgress size={16} sx={{ ml: 1 }} />
                    )}
                  </Box>
                </Box>
                {subscriptionInfo.subscriptionId !== 'free' && (
                  <>
                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                      <Typography>ID de Suscripción:</Typography>
                      <Typography>{subscriptionInfo.subscriptionId}</Typography>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                      <Typography>Próximo cobro:</Typography>
                      <Typography>
                        {subscriptionInfo.nextBillingDate ? (
                          <>
                            {new Date(
                              subscriptionInfo.nextBillingDate
                            ).toLocaleDateString()}
                            {daysRemaining > 0 && (
                              <Typography
                                component="span"
                                color="text.secondary"
                                sx={{ ml: 1 }}
                              >
                                ({daysRemaining} días)
                              </Typography>
                            )}
                          </>
                        ) : (
                          "No disponible"
                        )}
                      </Typography>
                    </Box>
                  </>
                )}
              </Stack>
            </Box>

            <Box>
              <Typography variant="subtitle1" gutterBottom>
                Características del plan:
              </Typography>
              <Stack spacing={1}>
                {getPlanFeatures().map((feature, index) => {
                  const category = feature.includes("ofertas")
                    ? "ofertas"
                    : feature.includes("eventos")
                    ? "eventos"
                    : feature.includes("viajes")
                    ? "viajes"
                    : feature.includes("autos")
                    ? "autos"
                    : "general";

                  return (
                    <Box
                      key={index}
                      sx={{ display: "flex", alignItems: "center", gap: 1 }}
                    >
                      <CategoryIcon category={category} />
                      <Typography variant="body2">{feature}</Typography>
                    </Box>
                  );
                })}
              </Stack>
            </Box>

            {currentStatus === "ACTIVE" && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 2,
                }}
              >
                {!showConfirmation ? (
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => setShowConfirmation(true)}
                    startIcon={<CancelIcon />}
                    disabled={isUpdating}
                  >
                    Cancelar Suscripción
                  </Button>
                ) : (
                  <Box sx={{ width: "100%", textAlign: "center" }}>
                    <Alert severity="warning" sx={{ mb: 2 }}>
                      Para completar la cancelación necesitarás:
                      <ol style={{ marginTop: "10px", textAlign: "left" }}>
                        1. Confirmar aquí para actualizar tu estado 2. Cancelar
                        en PayPal (se abrirá en una nueva ventana)
                      </ol>
                    </Alert>
                    <Stack direction="row" spacing={2} justifyContent="center">
                      <Button
                        variant="outlined"
                        onClick={() => setShowConfirmation(false)}
                      >
                        No, mantener suscripción
                      </Button>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={handleCancelSubscription}
                        disabled={isUpdating}
                        startIcon={
                          isUpdating ? (
                            <CircularProgress size={20} />
                          ) : (
                            <CancelIcon />
                          )
                        }
                      >
                        Sí, cancelar suscripción
                      </Button>
                    </Stack>
                  </Box>
                )}
              </Box>
            )}

            {error && (
              <Alert severity="error" onClose={() => setError(null)}>
                {error}
              </Alert>
            )}
          </Stack>
        </CardContent>
      </Card>
    </Box>
  );
};

// Componente de éxito
const SubscriptionSuccess = ({ subscriptionInfo, onContinue }) => {
  return (
    <Stack
      spacing={3}
      alignItems="center"
      sx={{ py: 4, maxWidth: 600, mx: "auto" }}
    >
      <CheckCircleOutlineIcon sx={{ fontSize: 64, color: "success.main" }} />
      <Typography variant="h5">¡Suscripción Exitosa!</Typography>
      <Typography variant="body1" color="text.secondary" align="center">
        Gracias por suscribirte. Tu suscripción está activa y ya puedes comenzar
        a disfrutar de todos los beneficios.
      </Typography>
      <Box sx={{ width: "100%" }}>
        <Card>
          <CardContent>
            <Typography variant="subtitle1" gutterBottom>
              Detalles de tu suscripción:
            </Typography>
            <Stack spacing={1}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography>ID de Suscripción:</Typography>
                <Typography>{subscriptionInfo.subscriptionId}</Typography>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography>Plan:</Typography>
                <Typography>{subscriptionInfo.planName}</Typography>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography>Estado:</Typography>
                <Typography color="success.main">
                  {subscriptionInfo.status}
                </Typography>
              </Box>
            </Stack>
          </CardContent>
        </Card>
      </Box>
      <Button
        variant="contained"
        size="large"
        onClick={onContinue}
        endIcon={<ArrowForwardIcon />}
      >
        Continuar a mi cuenta
      </Button>
    </Stack>
  );
};

const SubscriptionCheckout = () => {
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [billingInterval, setBillingInterval] = useState("mensual");
  const [subscriptionComplete, setSubscriptionComplete] = useState(false);
  const [subscriptionDetails, setSubscriptionDetails] = useState(null);
  const navigate = useNavigate();

  // Get subscription context and auth
  const {
    subscription,
    loading: subscriptionLoading,
    setSubscription,
  } = useSubscriptionContext();
  const { user, loading: authLoading } = useAuth();

  // Show loading state while either auth or subscription is loading
  if (authLoading || subscriptionLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!user) {
    navigate("/login");
    return null;
  }

  if (subscription?.status === SUBSCRIPTION_STATUS.ACTIVE) {
    return (
      <Container>
        <AppBar position="fixed" sx={{ bgcolor: "#312783" }}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => navigate(-1)}
            >
              <ChevronLeftRoundedIcon />
            </IconButton>
            <Typography
              variant="h6"
              sx={{ flexGrow: 1, textAlign: "center", fontWeight: "bold" }}
            >
              Estado de Suscripción
            </Typography>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => navigate("/home")}
            >
              <HomeIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Box sx={{ pt: 8 }}>
          <SubscriptionStatus
            subscriptionInfo={subscription}
            onCancelSubscription={async () => {
              try {
                const cancelledSubscription = {
                  ...subscription,
                  status: SUBSCRIPTION_STATUS.CANCELLED,
                  cancelDate: new Date().toISOString(),
                  lastUpdated: new Date().toISOString(),
                };

                await updateDoc(doc(db, "users", user.uid), {
                  subscription: cancelledSubscription,
                });

                setSubscription(cancelledSubscription);
                navigate("/home");
              } catch (error) {
                console.error("Error cancelling subscription:", error);
              }
            }}
          />
        </Box>
      </Container>
    );
  }

  if (subscriptionComplete) {
    return (
      <Container>
        <AppBar position="fixed" sx={{ bgcolor: "#312783" }}>
          <Toolbar>
            <Typography
              variant="h6"
              sx={{ flexGrow: 1, textAlign: "center", fontWeight: "bold" }}
            >
              Suscripción Completada
            </Typography>
          </Toolbar>
        </AppBar>
        <Box sx={{ pt: 8 }}>
          <SubscriptionSuccess
            subscriptionInfo={subscriptionDetails}
            onContinue={() => navigate("/home")}
          />
        </Box>
      </Container>
    );
  }

  return (
    <Container>
      <AppBar position="fixed" sx={{ bgcolor: "#312783" }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={() => navigate(-1)}>
            <ChevronLeftRoundedIcon />
          </IconButton>
          <Typography
            variant="h6"
            sx={{ flexGrow: 1, textAlign: "center", fontWeight: "bold" }}
          >
            Subscripción
          </Typography>
        </Toolbar>
      </AppBar>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Grid container spacing={3} sx={{ mt: 5, pt: 10 }}>
          <Grid item xs={12}>
            <PlanSelection
              selectedPlan={selectedPlan}
              setSelectedPlan={setSelectedPlan}
              billingInterval={billingInterval}
              setBillingInterval={setBillingInterval}
            />
          </Grid>
          {selectedPlan && (
            <Grid item xs={12}>
              <PaymentMethod
                selectedPlan={selectedPlan}
                billingInterval={billingInterval}
                onPaymentComplete={async (details) => {
                  try {
                    const subscriptionData = {
                      status: SUBSCRIPTION_STATUS.ACTIVE,
                      subscriptionId: details.id,
                      planId: selectedPlan.plan_id,
                      planName: selectedPlan.name,
                      price: selectedPlan.price,
                      interval: billingInterval,
                      startDate: new Date().toISOString(),
                      nextBillingDate: details.billing_info.next_billing_time,
                      lastUpdated: new Date().toISOString(),
                    };

                    await updateDoc(doc(db, "users", user.uid), {
                      subscription: subscriptionData,
                    });

                    setSubscription(subscriptionData);
                    setSubscriptionDetails(subscriptionData);
                    setSubscriptionComplete(true);
                  } catch (error) {
                    console.error("Error saving subscription:", error);
                  }
                }}
              />
            </Grid>
          )}
        </Grid>
      </ThemeProvider>
    </Container>
  );
};

export default SubscriptionCheckout;
