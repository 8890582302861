import React, { useState, useEffect } from "react";
import {
  Container,
  Typography,
  Toolbar,
  AppBar,
  TextField,
  Button,
  CircularProgress,
  Box,
  Alert,
  Snackbar,
  Autocomplete,
  InputAdornment,
} from "@mui/material";
import {
  Person as PersonIcon,
  LocationOn as LocationIcon,
  Badge as BadgeIcon,
} from "@mui/icons-material";
import {
  parsePhoneNumber,
  AsYouType,
  getCountries,
  getCountryCallingCode,
} from "libphonenumber-js";
import { auth, db } from "../firebase";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import Atras from "./atras";
import BottomToolbar from "./BottomToolbar";

const countriesData = getCountries()
  .map((countryCode) => {
    try {
      return {
        code: countryCode,
        name: new Intl.DisplayNames(["es"], { type: "region" }).of(countryCode),
        phoneCode: `+${getCountryCallingCode(countryCode)}`,
        flag: countryCode
          .toUpperCase()
          .replace(/./g, (char) =>
            String.fromCodePoint(char.charCodeAt(0) + 127397)
          ),
      };
    } catch (e) {
      return null;
    }
  })
  .filter(Boolean)
  .sort((a, b) => a.name.localeCompare(b.name));

const Edit = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    displayName: "",
    fullname: "",
    phone: "",
    point: "",
    nationalId: "",
  });

  const [selectedCountry, setSelectedCountry] = useState(
    countriesData.find((country) => country.code === "AR") || countriesData[0]
  );

  const [validations, setValidations] = useState({
    displayName: { error: false, message: "" },
    fullname: { error: false, message: "" },
    phone: { error: false, message: "" },
    point: { error: false, message: "" },
    nationalId: { error: false, message: "" },
  });

  const [isLoading, setIsLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const docRef = doc(db, "users", user.uid);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            const userData = docSnap.data();

            if (userData.phone) {
              try {
                const phoneNumber = parsePhoneNumber(userData.phone);
                const country = countriesData.find(
                  (c) => c.code === phoneNumber.country
                );
                if (country) {
                  setSelectedCountry(country);
                  const formatter = new AsYouType(country.code);
                  const formattedPhone = formatter.input(userData.phone);

                  setFormData({
                    displayName: userData.displayName || "",
                    fullname: userData.fullname || "",
                    phone: formattedPhone || "",
                    point: userData.point || "",
                    nationalId: userData.nationalId || "",
                  });
                }
              } catch (e) {
                console.error("Error parsing phone number:", e);
                setFormData({
                  displayName: userData.displayName || "",
                  fullname: userData.fullname || "",
                  phone: userData.phone || "",
                  point: userData.point || "",
                  nationalId: userData.nationalId || "",
                });
              }
            } else {
              setFormData({
                displayName: userData.displayName || "",
                fullname: userData.fullname || "",
                phone: "",
                point: userData.point || "",
                nationalId: userData.nationalId || "",
              });
            }
          }
        } catch (error) {
          console.error("Error loading user data:", error);
          setSnackbar({
            open: true,
            message: "Error al cargar los datos del usuario",
            severity: "error",
          });
        }
      } else {
        navigate("/login");
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const formatPhoneNumber = (value, country) => {
    try {
      const formatter = new AsYouType(country.code);
      let formatted = formatter.input(value);

      if (!formatted.startsWith(country.phoneCode)) {
        formatted = formatted.replace(/^\D+/g, "");
        formatted = `${country.phoneCode} ${formatted}`;
      }

      return formatted;
    } catch (e) {
      return value;
    }
  };

  const handleChange = (field) => (event) => {
    let value = event.target.value;

    if (field === "phone") {
      value = formatPhoneNumber(value, selectedCountry);
    }

    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));

    setValidations((prev) => ({
      ...prev,
      [field]: { error: false, message: "" },
    }));

    setHasChanges(true);
  };

  const handleCountryChange = (event, newValue) => {
    if (newValue) {
      setSelectedCountry(newValue);
      if (formData.phone) {
        const phoneWithoutCode = formData.phone.replace(/^\+\d+\s*/, "");
        const newPhone = formatPhoneNumber(phoneWithoutCode, newValue);
        setFormData((prev) => ({
          ...prev,
          phone: newPhone,
        }));
      }
    }
  };

  const validateFullname = (fullname) => {
    if (!fullname || fullname.trim() === "") {
      return { error: true, message: "El nombre completo es requerido" };
    }
    if (fullname.trim().length < 5) {
      return {
        error: true,
        message: "El nombre completo debe tener al menos 5 caracteres",
      };
    }
    if (!/^[A-Za-záéíóúñÁÉÍÓÚÑ\s]+$/.test(fullname)) {
      return {
        error: true,
        message: "El nombre solo puede contener letras y espacios",
      };
    }
    return { error: false, message: "" };
  };

  const validateDisplayName = (displayName) => {
    if (!displayName || displayName.trim() === "") {
      return { error: true, message: "El nombre de usuario es requerido" };
    }
    if (displayName.trim().length < 3) {
      return {
        error: true,
        message: "El nombre de usuario debe tener al menos 3 caracteres",
      };
    }
    if (!/^[A-Za-z0-9_]+$/.test(displayName)) {
      return {
        error: true,
        message: "Solo letras, números y guiones bajos",
      };
    }
    return { error: false, message: "" };
  };

  const validatePhone = (phoneNumber, country) => {
    if (!phoneNumber.trim()) {
      return "El número de teléfono es requerido";
    }

    try {
      const fullNumber = phoneNumber.startsWith("+")
        ? phoneNumber
        : `${country.phoneCode}${phoneNumber.replace(/^\D+/g, "")}`;

      const parsedNumber = parsePhoneNumber(fullNumber, country.code);

      if (!parsedNumber.isValid()) {
        return `Número inválido para ${country.name}`;
      }

      return "";
    } catch (error) {
      return "Formato de número inválido";
    }
  };

  const validatePoint = (point) => {
    if (!point || point.trim() === "") {
      return { error: true, message: "La localidad es requerida" };
    }
    return { error: false, message: "" };
  };

  const validateNationalId = (nationalId) => {
    if (!nationalId || nationalId.trim() === "") {
      return { error: true, message: "El documento de identidad es requerido" };
    }
    if (!/^\d{8,10}$/.test(nationalId)) {
      return {
        error: true,
        message: "El documento debe tener entre 8 y 10 dígitos",
      };
    }
    return { error: false, message: "" };
  };

  const validateForm = () => {
    const newValidations = {
      fullname: validateFullname(formData.fullname),
      displayName: validateDisplayName(formData.displayName),
      point: validatePoint(formData.point),
    };

    const phoneError = validatePhone(formData.phone, selectedCountry);
    newValidations.phone = {
      error: !!phoneError,
      message: phoneError,
    };

    newValidations.nationalId = validateNationalId(formData.nationalId);

    setValidations(newValidations);
    return !Object.values(newValidations).some(
      (validation) => validation.error
    );
  };

  const handleClose = () => {
    if (hasChanges) {
      if (
        window.confirm(
          "¿Seguro que deseas salir? Los cambios no guardados se perderán."
        )
      ) {
        navigate(-1);
      }
    } else {
      navigate(-1);
    }
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    setIsLoading(true);
    try {
      if (!auth.currentUser) throw new Error("No user logged in");

      const userRef = doc(db, "users", auth.currentUser.uid);
      const fullNumber = formData.phone.startsWith("+")
        ? formData.phone
        : `${selectedCountry.phoneCode}${formData.phone.replace(/^\D+/g, "")}`;

      const updateData = {
        displayName: formData.displayName,
        fullname: formData.fullname,
        phone: fullNumber,
        point: formData.point,
        nationalId: formData.nationalId,
      };

      await updateDoc(userRef, updateData);

      setSnackbar({
        open: true,
        message: "Perfil actualizado con éxito",
        severity: "success",
      });

      setHasChanges(false);
      setTimeout(() => navigate(-1), 1500);
    } catch (error) {
      console.error("Error updating profile:", error);
      setSnackbar({
        open: true,
        message: "Error al actualizar el perfil",
        severity: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container>
      <AppBar position="fixed" sx={{ bgcolor: "#312783" }}>
        <Toolbar
          sx={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px",
          }}
        >
          <Box
            onClick={handleClose}
            sx={{ cursor: "pointer", position: "absolute", left: "10px" }}
          >
            <Atras color="inherit" />
          </Box>
          <Typography variant="h5" color="white">
            Editar perfil
          </Typography>
        </Toolbar>
      </AppBar>

      <Container sx={{ mt: 16, mb: 15 }}>
        <TextField
          fullWidth
          margin="normal"
          label="Nombre de Usuario"
          value={formData.displayName}
          onChange={handleChange("displayName")}
          error={validations.displayName.error}
          helperText={validations.displayName.message}
          disabled={isLoading}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PersonIcon />
              </InputAdornment>
            ),
          }}
        />

        <TextField
          fullWidth
          margin="normal"
          label="Nombre Completo"
          value={formData.fullname}
          onChange={handleChange("fullname")}
          error={validations.fullname.error}
          helperText={validations.fullname.message}
          disabled={isLoading}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PersonIcon />
              </InputAdornment>
            ),
          }}
        />

        <TextField
          fullWidth
          margin="normal"
          label="Documento de Identidad"
          value={formData.nationalId}
          onChange={handleChange("nationalId")}
          error={validations.nationalId.error}
          helperText={validations.nationalId.message}
          disabled={isLoading}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <BadgeIcon />
              </InputAdornment>
            ),
          }}
        />

        <Autocomplete
          options={countriesData}
          value={selectedCountry}
          onChange={handleCountryChange}
          getOptionLabel={(option) =>
            `${option.flag} ${option.name} (${option.phoneCode})`
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label="País"
              variant="outlined"
              margin="normal"
              fullWidth
            />
          )}
          isOptionEqualToValue={(option, value) => option.code === value.code}
          disabled={isLoading}
        />

        <TextField
          fullWidth
          margin="normal"
          label="Teléfono"
          value={formData.phone}
          onChange={handleChange("phone")}
          error={validations.phone.error}
          helperText={
            validations.phone.message ||
            `Formato para ${selectedCountry.name}: ${selectedCountry.phoneCode}`
          }
          disabled={isLoading}
          placeholder={`${selectedCountry.phoneCode} XXXXXXXXX`}
        />

        <TextField
          fullWidth
          margin="normal"
          label="Localidad"
          value={formData.point}
          onChange={handleChange("point")}
          error={validations.point.error}
          helperText={validations.point.message}
          disabled={isLoading}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LocationIcon />
              </InputAdornment>
            ),
          }}
        />

        <Button
          fullWidth
          variant="contained"
          onClick={handleSubmit}
          disabled={isLoading || !hasChanges}
          sx={{
            mt: 3,
            mb: 2,
            height: 42,
            textTransform: "none",
            fontSize: "1rem",
          }}
        >
          {isLoading ? <CircularProgress size={24} /> : "Guardar cambios"}
        </Button>
      </Container>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
      >
        <Alert
          onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>

      <BottomToolbar />
    </Container>
  );
};

export default Edit;
