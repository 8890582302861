import React, { useEffect, useState, useCallback } from 'react';
import { auth, db } from '../firebase';
import { 
  query, 
  collection, 
  where, 
  onSnapshot,
  getDocs,
  serverTimestamp,
  updateDoc
} from 'firebase/firestore';
import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useLocation } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';

export const subscribeToNotifications = (callback) => {
  const user = auth.currentUser;
  if (!user) return null;

  const notificationsRef = collection(db, 'notifications');
  const userNotificationsQuery = query(
    notificationsRef,
    where('userId', '==', user.uid),
    where('read', '==', false)
  );

  return onSnapshot(userNotificationsQuery, (snapshot) => {
    callback(snapshot.size > 0);
  });
};

const NotificationIcon = () => {
  const [hasNewNotifications, setHasNewNotifications] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();

  const markNotificationsAsRead = useCallback(async () => {
    const user = auth.currentUser;
    if (!user) return;

    try {
      const notificationsRef = collection(db, 'notifications');
      const unreadQuery = query(
        notificationsRef,
        where('userId', '==', user.uid),
        where('read', '==', false)
      );

      const unreadDocs = await getDocs(unreadQuery);
      
      const updatePromises = unreadDocs.docs.map(doc => 
        updateDoc(doc.ref, { 
          read: true,
          readAt: serverTimestamp()
        })
      );

      await Promise.all(updatePromises);
    } catch (err) {
      console.error('Error marking notifications as read:', err);
      setError(err.message);
    }
  }, []);

  useEffect(() => {
    let unsubscribe = null;
    const user = auth.currentUser;

    const subscribeToNotifications = async () => {
      if (!user) {
        setIsLoading(false);
        return;
      }

      try {
        const notificationsRef = collection(db, 'notifications');
        const userNotificationsQuery = query(
          notificationsRef,
          where('userId', '==', user.uid),
          where('read', '==', false)
        );

        unsubscribe = onSnapshot(userNotificationsQuery, 
          (snapshot) => {
            setHasNewNotifications(snapshot.size > 0);
            setIsLoading(false);
          },
          (err) => {
            console.error('Error in notification subscription:', err);
            setError(err.message);
            setIsLoading(false);
          }
        );
      } catch (err) {
        console.error('Error setting up notification subscription:', err);
        setError(err.message);
        setIsLoading(false);
      }
    };

    subscribeToNotifications();

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  useEffect(() => {
    if (location.pathname === '/notifications') {
      markNotificationsAsRead();
    }
  }, [location.pathname, markNotificationsAsRead]);

  if (isLoading) {
    return <CircularProgress size={24} />;
  }

  if (error) {
    return <NotificationsIcon sx={{ color: 'error.main' }} />;
  }

  return (
    <Badge 
      color="primary" 
      variant="dot" 
      invisible={!hasNewNotifications}
      sx={{
        '& .MuiBadge-badge': {
          backgroundColor: '#312783',
          animation: hasNewNotifications ? 'pulse 2s infinite' : 'none',
          '@keyframes pulse': {
            '0%': { transform: 'scale(1)' },
            '50%': { transform: 'scale(1.2)' },
            '100%': { transform: 'scale(1)' }
          }
        }
      }}
    >
      <NotificationsIcon />
    </Badge>
  );
};

export default NotificationIcon;