import React, { useEffect, useState } from "react";
import "./index.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  Navigate,
} from "react-router-dom";
import { auth } from "./firebase";
import { CardMedia } from "@mui/material";
import Login from "./routes/login";
import Register from "./routes/register";
import Home from "./routes/home";
import Profile from "./routes/profile";
import Events from "./routes/events";
import Members from "./routes/members";
import Edit from "./routes/edit";
import Notifications from "./routes/notifications";
import WelcomeDialog from "./routes/welcomeDialog";
import Perfil from "./routes/perfil";
import SettingNotification from "./routes/settingNotification";
import Terms from "./routes/terms";
import Policy from "./routes/privacyPolicy";
import EditEvents from "./routes/editEvents";
import EditOffers from "./routes/editOffers";
import PublishOffer from "./routes/publishOffer";
import PublishEvents from "./routes/publishEvents";
import Atras from "./routes/atras";
import NotificationIcon from "./routes/NotificationIcon";
import User from "./routes/user";
import HeaderPerfil from "./routes/HeaderPerfil";
import Copy from "./routes/copy";
import BottomToolbar from "./routes/BottomToolbar";
import { isLoggedIn, endSession } from "./session";
import logo from "./imgs/logo192.svg";
import Chats from "./routes/chats";
import ChatBox from "./routes/chatbox";
import Subscription from "./routes/subscription";
import Search from "./routes/search";
import CheckOut from "./routes/checkout";
import QRScannerPay from "./routes/QRScannerPay";
import CobrarCQR from "./routes/CobrarCQR";
import QRScanner from "./routes/QRScanner";
import Autos from "./routes/autos/cars";
import BottomNav from "./routes/autos/BottomNav";
import MyCars from "./routes/autos/myCars";
import Vehiculos from "./routes/autos/vehiculos";
import PublishCar from "./routes/autos/publishCar";
import VerAuto from "./routes/autos/ver";
import Viajes from "./routes/viajes/viajes";
import Properties from "./routes/viajes/myProperties";
import Trips from "./routes/viajes/trips";
import PublishProperty from "./routes/viajes/publishProperty";
import VerLugar from "./routes/viajes/ver";
import HeaderViajes from "./routes/viajes/Header3";
import FloatingMenu from "./routes/FloatingMenu";
import UserMap from "./routes/UserMap";
import { SubscriptionProvider, useSubscriptionContext } from './routes/SubscriptionContext';


const Loading = () => (
  <div className="loading-container">
    <CardMedia
      className="loading-img"
      alt="Cargando..."
      component="img"
      image={logo}
    />
  </div>
);

const FloatingMenuWrapper = ({ user }) => {
  const location = useLocation();
  const excludedPaths = [
    '/login', 
    '/register', 
    '/map', 
    '/profile',
    '/perfil', 
    '/chatbox', 
    '/QRScannerPay', 
    '/CobrarCQR', 
    '/QRScanner'
  ];
  
  const isExcludedPath = 
    excludedPaths.includes(location.pathname) || 
    location.pathname.startsWith('/user/') ||
    location.pathname.startsWith('/perfil/') ||
    location.pathname.startsWith('/profile/') ||
    location.pathname.startsWith('/chatbox/');

  const showFloatingMenu = user && !isExcludedPath;

  return showFloatingMenu ? <FloatingMenu /> : null;
};
// Componente para proteger rutas que requieren autenticación
const ProtectedRoute = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const user = await isLoggedIn();
        setIsAuthenticated(!!user);
      } catch (error) {
        console.error("Error checking auth:", error);
      } finally {
        setLoading(false);
      }
    };

    checkAuth();
  }, []);

  if (loading) {
    return <Loading />;
  }

  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
};

// Componente para proteger rutas que requieren suscripción
const ProtectedPublish = ({ children }) => {
  const { subscription } = useSubscriptionContext();
  const isSubscriptionActive = subscription?.status === 'ACTIVE';
  const location = useLocation(); // Get current location

  // Check authentication FIRST
  const isLoggedIn = !!auth.currentUser; // Directly check if there's a current user

  if (!isLoggedIn) {
    return <Navigate to="/login" state={{ from: location }} replace />; // Redirect to login with location state
  }

  // THEN, if authenticated, check subscription
  if (!isSubscriptionActive) {
    return <Navigate to="/subscription" state={{ from: location }} replace />; // Redirect to subscription with location state
  }

  return children;
};

const AppRoutes = ({ user, handleLogout }) => {
  return (
    <>
      <Routes>
        {/* Rutas públicas */}
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacyPolicy" element={<Policy />} />

        {/* Ruta raíz */}
        <Route
          path="/"
          element={
            user ? (
              <Home handleLogout={handleLogout} />
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />

        {/* Rutas protegidas básicas */}
        <Route
          path="/home"
          element={
            <ProtectedRoute>
              <Home handleLogout={handleLogout} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <ProtectedRoute>
              <Profile />
            </ProtectedRoute>
          }
        />
        <Route
          path="/events"
          element={
            <ProtectedRoute>
              <Events />
            </ProtectedRoute>
          }
        />
        <Route
          path="/notifications"
          element={
            <ProtectedRoute>
              <Notifications />
            </ProtectedRoute>
          }
        />
        <Route
          path="/editEvents"
          element={
            <ProtectedRoute>
              <EditEvents />
            </ProtectedRoute>
          }
        />
        <Route
          path="/copy"
          element={
            <ProtectedRoute>
              <Copy />
            </ProtectedRoute>
          }
        />
        <Route
          path="/atras"
          element={
            <ProtectedRoute>
              <Atras />
            </ProtectedRoute>
          }
        />
        <Route
          path="/HeaderPerfil"
          element={
            <ProtectedRoute>
              <HeaderPerfil />
            </ProtectedRoute>
          }
        />
        <Route
          path="/subscription"
          element={
            <ProtectedRoute>
              <Subscription />
            </ProtectedRoute>
          }
        />
        <Route
          path="/chats"
          element={
            <ProtectedRoute>
              <Chats />
            </ProtectedRoute>
          }
        />
        <Route
          path="/map"
          element={
            <ProtectedRoute>
              <UserMap />
            </ProtectedRoute>
          }
        />
        <Route
          path="/user/:userId"
          element={
            <ProtectedRoute>
              <User />
            </ProtectedRoute>
          }
        />
        <Route
          path="/chatbox/:type/:itemId/:otherUserId"
          element={
            <ProtectedRoute>
              <ChatBox />
            </ProtectedRoute>
          }
        />
        <Route
          path="/QRScannerPay"
          element={
            <ProtectedRoute>
              <QRScannerPay />
            </ProtectedRoute>
          }
        />
        <Route
          path="/CobrarCQR"
          element={
            <ProtectedRoute>
              <CobrarCQR />
            </ProtectedRoute>
          }
        />
        <Route
          path="/QRScanner"
          element={
            <ProtectedRoute>
              <QRScanner />
            </ProtectedRoute>
          }
        />
        <Route
          path="/BottomToolbar"
          element={
            <ProtectedRoute>
              <BottomToolbar />
            </ProtectedRoute>
          }
        />
        <Route
          path="/search"
          element={
            <ProtectedRoute>
              <Search />
            </ProtectedRoute>
          }
        />
        <Route
          path="/welcomeDialog"
          element={
            <ProtectedRoute>
              <WelcomeDialog />
            </ProtectedRoute>
          }
        />
        <Route
          path="/profile/:userId"
          element={
            <ProtectedRoute>
              <User />
            </ProtectedRoute>
          }
        />
        <Route
          path="/NotificationIcon"
          element={
            <ProtectedRoute>
              <NotificationIcon />
            </ProtectedRoute>
          }
        />
        <Route
          path="/editOffers"
          element={
            <ProtectedRoute>
              <EditOffers />
            </ProtectedRoute>
          }
        />
        <Route
          path="/settingNotification"
          element={
            <ProtectedRoute>
              <SettingNotification />
            </ProtectedRoute>
          }
        />
        <Route
          path="/members"
          element={
            <ProtectedRoute>
              <Members />
            </ProtectedRoute>
          }
        />
        <Route
          path="/checkout/:type/:itemId"
          element={
            <ProtectedRoute>
              <CheckOut />
            </ProtectedRoute>
          }
        />
        <Route
          path="/checkout"
          element={
            <ProtectedRoute>
              <CheckOut />
            </ProtectedRoute>
          }
        />
        <Route
          path="/perfil/:uid"
          element={
            <ProtectedRoute>
              <Perfil />
            </ProtectedRoute>
          }
        />
        <Route
          path="/edit"
          element={
            <ProtectedRoute>
              <Edit />
            </ProtectedRoute>
          }
        />

        {/* Rutas de autos protegidas */}
        <Route
          path="/cars"
          element={
            <ProtectedRoute>
              <Autos />
            </ProtectedRoute>
          }
        />
        <Route
          path="/BottomNav"
          element={
            <ProtectedRoute>
              <BottomNav />
            </ProtectedRoute>
          }
        />
        <Route
          path="/myCars"
          element={
            <ProtectedRoute>
              <MyCars />
            </ProtectedRoute>
          }
        />
        <Route
          path="/vehiculos"
          element={
            <ProtectedRoute>
              <Vehiculos />
            </ProtectedRoute>
          }
        />
        <Route
          path="/VerAuto"
          element={
            <ProtectedRoute>
              <VerAuto />
            </ProtectedRoute>
          }
        />
        <Route
          path="/autos/ver/:carId"
          element={
            <ProtectedRoute>
              <VerAuto />
            </ProtectedRoute>
          }
        />

        {/* Rutas de viajes protegidas */}
        <Route
          path="/viajes"
          element={
            <ProtectedRoute>
              <Viajes />
            </ProtectedRoute>
          }
        />
        <Route
          path="/Properties"
          element={
            <ProtectedRoute>
              <Properties />
            </ProtectedRoute>
          }
        />
        <Route
          path="/Trips"
          element={
            <ProtectedRoute>
              <Trips />
            </ProtectedRoute>
          }
        />
        <Route
          path="/viajes/ver/:placeId"
          element={
            <ProtectedRoute>
              <VerLugar />
            </ProtectedRoute>
          }
        />
        <Route
          path="/VerLugar"
          element={
            <ProtectedRoute>
              <VerLugar />
            </ProtectedRoute>
          }
        />
        <Route
          path="/HeaderViajes"
          element={
            <ProtectedRoute>
              <HeaderViajes />
            </ProtectedRoute>
          }
        />

        {/* Rutas que requieren suscripción */}
        <Route
          path="/publishOffer"
          element={
            <ProtectedRoute>
              <ProtectedPublish>
                <PublishOffer />
              </ProtectedPublish>
            </ProtectedRoute>
          }
        />
        <Route
          path="/publishEvents"
          element={
            <ProtectedRoute>
              <ProtectedPublish>
                <PublishEvents />
              </ProtectedPublish>
            </ProtectedRoute>
          }
        />
        <Route
          path="/publishProperty"
          element={
            <ProtectedRoute>
              <ProtectedPublish>
                <PublishProperty />
              </ProtectedPublish>
            </ProtectedRoute>
          }
        />
        <Route
          path="/publishCar"
          element={
            <ProtectedRoute>
              <ProtectedPublish>
                <PublishCar />
              </ProtectedPublish>
            </ProtectedRoute>
          }
        />

        {/* Ruta para manejar URLs no encontradas */}
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      <FloatingMenuWrapper user={user} />
    </>
  );
};

const App = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const user = await isLoggedIn();
        setUser(user);
      } catch (error) {
        console.error("Error al verificar la autenticación:", error);
      } finally {
        setLoading(false);
      }
    };

    checkAuth();
  }, []);

  const handleLogout = async () => {
    try {
      await endSession();
      setUser(null);
    } catch (error) {
      console.error("Error al cerrar sesión:", error);
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <Router>
      <SubscriptionProvider>
        <AppRoutes user={user} handleLogout={handleLogout} />
      </SubscriptionProvider>
    </Router>
  );
};

export default App;