import React, { useEffect, useState } from "react";
import {
  Container,
  AppBar,
  Toolbar,
  CardMedia,
  Typography,
  Avatar,
  ListItem,
  CircularProgress,
  Button,
  Grid,
  Chip,
} from "@mui/material";
import {
  Email as EmailIcon,
  Phone as PhoneIcon,
  LocationOn as LocationIcon,
  Person as PersonIcon,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { isLoggedIn } from "../session";
import { auth, db } from "../firebase";
import {
  collection,
  getDocs,
  doc,
  setDoc,
  updateDoc,
  deleteField,
  getDoc,
  query,
  where,
} from "firebase/firestore";
import logoSvg from "../imgs/logo.svg";
import BottomToolbar from "./BottomToolbar";
import Search from "./search";

const Members = () => {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [following, setFollowing] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const isAuthenticated = await isLoggedIn();
        if (isAuthenticated) {
          setLoading(true);

          const collections = ["offers", "events", "places", "cars"];
          const publishersSet = new Set();

          for (const collectionName of collections) {
            const collectionRef = collection(db, collectionName);
            const collectionSnapshot = await getDocs(collectionRef);

            collectionSnapshot.docs.forEach((doc) => {
              const userData = doc.data();
              if (userData.uid) {
                publishersSet.add(userData.uid);
              }
            });
          }

          const usersRef = collection(db, "users");
          const publishersArray = Array.from(publishersSet);

          if (publishersArray.length === 0) {
            setLoading(false);
            return;
          }

          const usersSnapshot = await getDocs(
            query(usersRef, where("uid", "in", publishersArray))
          );

          const usersData = usersSnapshot.docs.map((doc) => {
            const userData = doc.data();
            return {
              ...userData,
              publishedContent: collections.filter((col) =>
                publishersSet.has(userData.uid)
              ),
            };
          });

          setUsers(usersData);
          setFilteredUsers(usersData);
        }
      } catch (error) {
        console.error("Error obtaining users:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    const fetchFollowing = async () => {
      try {
        const isAuthenticated = await isLoggedIn();
        if (isAuthenticated && auth.currentUser) {
          const { uid } = auth.currentUser;
          const userFollowingRef = doc(db, "userFollowing", uid);
          const userFollowingDoc = await getDoc(userFollowingRef);

          if (userFollowingDoc.exists()) {
            const followingData = userFollowingDoc.data();
            setFollowing(Object.keys(followingData));
          }
        }
      } catch (error) {
        console.error("Error fetching following:", error);
      }
    };

    fetchFollowing();
  }, []);

  const handleFollow = async (companyId) => {
    try {
      const isAuthenticated = await isLoggedIn();
      if (!isAuthenticated) {
        navigate("/login");
        return;
      }

      const { uid } = auth.currentUser;
      if (!uid) return;

      setLoading(true);
      const userFollowingRef = doc(db, "userFollowing", uid);
      const companyFollowersRef = doc(db, "companyFollowers", companyId);

      const isFollowing = following.includes(companyId);

      if (isFollowing) {
        await updateDoc(userFollowingRef, {
          [companyId]: deleteField(),
        });
        await updateDoc(companyFollowersRef, {
          [uid]: deleteField(),
        });
        setFollowing(following.filter((id) => id !== companyId));
      } else {
        await setDoc(userFollowingRef, { [companyId]: true }, { merge: true });
        await setDoc(companyFollowersRef, { [uid]: true }, { merge: true });
        setFollowing([...following, companyId]);
      }
    } catch (error) {
      console.error("Error handling follow:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleClickPerfil = (user) => {
    if (user?.uid) {
      navigate(`/perfil/${user.uid}`);
    }
  };

  const handleSearchResults = (results) => {
    setFilteredUsers(results);
  };

  const handleClearSearch = () => {
    setFilteredUsers(users);
  };

  return (
    <Container>
      <AppBar
        position="fixed"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "10px",
          bgcolor: "#fff",
        }}
      >
        <Toolbar sx={{ alignItems: "center" }}>
          <CardMedia
            component="img"
            image={logoSvg}
            title="Notiline"
            sx={{ width: "160px", my: 2 }}
          />
        </Toolbar>
      </AppBar>

      <Container sx={{ mt: 13, mb: 10 }}>
        <Search 
          data={users}
          onSearchResults={handleSearchResults}
          onClearSearch={handleClearSearch}
          searchKeys={['displayName', 'email', 'phone', 'point']}
          placeholder="Buscar usuarios..."
          noResultsTitle="No se encontraron usuarios"
          noResultsMessage="No hay usuarios que coincidan con tu búsqueda"
        />
        {loading ? (
          <div style={{ textAlign: "center", color: "#312783", marginTop: 20 }}>
            <CircularProgress />
          </div>
        ) : (
          <Grid container spacing={2}>
            {filteredUsers.map((user) => (
              <Grid item xs={12} sm={6} md={4} key={user.uid}>
                <ListItem
                  sx={{
                    border: "1px solid #e0e0e0",
                    borderRadius: "8px",
                    padding: "16px",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <Grid container spacing={2} alignItems="center">
                    <Grid item>
                      <Avatar
                        src={user.profileImageUrl}
                        sx={{
                          bgcolor: "#312783",
                          width: 56,
                          height: 56,
                          border: "1.2px solid #312783",
                        }}
                      >
                        {!user.profileImageUrl &&
                          (user.displayName ? (
                            user.displayName[0].toUpperCase()
                          ) : (
                            <PersonIcon />
                          ))}
                      </Avatar>
                    </Grid>
                    <Grid item xs>
                      <Button onClick={() => handleClickPerfil(user)}>
                        <Typography variant="h6">
                          {user.displayName || "Usuario Desconocido"}
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>

                  <Grid container spacing={1} sx={{ mt: 2 }}>
                    <Grid item xs={12}>
                      <Typography variant="body2" color="text.secondary">
                        <EmailIcon
                          fontSize="small"
                          sx={{ mr: 1, verticalAlign: "middle" }}
                        />
                        {user.email || "No se proporcionó email"}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2" color="text.secondary">
                        <PhoneIcon
                          fontSize="small"
                          sx={{ mr: 1, verticalAlign: "middle" }}
                        />
                        {user.phone || "No se proporcionó teléfono"}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body2" color="text.secondary">
                        <LocationIcon
                          fontSize="small"
                          sx={{ mr: 1, verticalAlign: "middle" }}
                        />
                        {user.point || "No se proporcionó ubicación"}
                      </Typography>
                    </Grid>
                  </Grid>

                  <div style={{ marginTop: 16, marginBottom: 16 }}>
                    {user.publishedContent?.map((content) => (
                      <Chip
                        key={content}
                        label={content}
                        size="small"
                        sx={{ mr: 0.5, mb: 0.5 }}
                      />
                    ))}
                  </div>

                  <Button
                    onClick={() => handleFollow(user.uid)}
                    disabled={loading}
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    {following.includes(user.uid)
                      ? "Dejar de seguir"
                      : "Seguir"}
                  </Button>
                </ListItem>
              </Grid>
            ))}
          </Grid>
        )}
      </Container>
      <BottomToolbar />
    </Container>
  );
};

export default Members;