import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
} from "@mui/material";
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

const WelcomeDialog = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const hasSeenWelcome = localStorage.getItem("hasSeenWelcome");
    if (!hasSeenWelcome) {
      setOpen(true);
    }
  }, []);

  const handleClose = () => {
    localStorage.setItem("hasSeenWelcome", "true");
    setOpen(false);
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent className="sm:max-w-md">
        <Box className="flex flex-col items-center text-center p-6 bg-gradient-to-b from-purple-50 to-white rounded-lg">
          <Box className="mb-4">
            <AutoAwesomeIcon className="w-12 h-12 text-purple-600 animate-pulse" />
          </Box>

          <Typography className="text-2xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-purple-600 to-blue-600">
            ¡Bienvenido a Notiline!
          </Typography>

          <Typography className="mb-4 text-gray-600">
            Tu destino para descubrir las mejores ofertas, promociones, eventos,
            vehículos y lugares. Mantente al día con tus tiendas favoritas y no
            te pierdas ningún descuento.
          </Typography>

          <Box className="space-y-2 mb-4">
            <Typography className="text-sm text-gray-500">
              ✓ Recibe notificaciones de ofertas exclusivas
            </Typography>
            <Typography className="text-sm text-gray-500">
              ✓ Sigue a tus tiendas favoritas
            </Typography>
            <Typography className="text-sm text-gray-500">
              ✓ Comparte y descubre cupones de descuento
            </Typography>
          </Box>
        </Box>

        <DialogActions className="mt-4">
          <Button
            onClick={handleClose}
            className="w-full bg-purple-600 hover:bg-purple-700 text-white py-2 px-4 rounded-lg transition-colors duration-200"
          >
            ¡Empezar a explorar!
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default WelcomeDialog;
