/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  CircularProgress,
  Container,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  TextField,
  Typography,
  Autocomplete,
} from "@mui/material";
import {
  Visibility,
  VisibilityOff,
  Person as PersonIcon,
  Email as EmailIcon,
  LocationOn as LocationIcon,
  Badge as BadgeIcon,
} from "@mui/icons-material";
import logoSvg from "../imgs/logo192.png";
import {
  parsePhoneNumber,
  AsYouType,
  getCountries,
  getCountryCallingCode,
} from "libphonenumber-js";
import { createUser } from "../firebase";

// Países database using libphonenumber-js
const countriesData = getCountries()
  .map((countryCode) => {
    try {
      return {
        code: countryCode,
        name: new Intl.DisplayNames(["es"], { type: "region" }).of(countryCode),
        phoneCode: `+${getCountryCallingCode(countryCode)}`,
        flag: countryCode
          .toUpperCase()
          .replace(/./g, (char) =>
            String.fromCodePoint(char.charCodeAt(0) + 127397)
          ),
      };
    } catch (e) {
      return null;
    }
  })
  .filter(Boolean)
  .sort((a, b) => a.name.localeCompare(b.name));

// ID validation patterns for different countries
const idPatterns = {
  AR: {
    pattern: /^\d{7,8}$/,
    name: "DNI",
    message: "El DNI debe tener 7 u 8 dígitos"
  },
  BR: {
    pattern: /^\d{11}$/,
    name: "CPF",
    message: "El CPF debe tener 11 dígitos"
  },
  CL: {
    pattern: /^\d{7,8}[-][0-9kK]$/,
    name: "RUT",
    message: "El RUT debe tener formato 12345678-9"
  },
  CO: {
    pattern: /^\d{8,10}$/,
    name: "Cédula",
    message: "La Cédula debe tener entre 8 y 10 dígitos"
  },
  MX: {
    pattern: /^[A-Z]{4}\d{6}[A-Z]{6}[0-9A-Z]\d$/,
    name: "CURP",
    message: "El CURP debe tener el formato correcto"
  },
  // Add more countries as needed
  DEFAULT: {
    pattern: /^.{4,20}$/,
    name: "ID",
    message: "El ID debe tener entre 4 y 20 caracteres"
  }
};

const Register = () => {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    fullName: "",
    displayName: "",
    nationalId: "",
    phone: "",
    point: "",
  });

  const [selectedCountry, setSelectedCountry] = useState(
    countriesData.find((country) => country.code === "AR") || countriesData[0]
  );

  const [validations, setValidations] = useState({
    email: { error: false, message: "" },
    password: { error: false, message: "" },
    confirmPassword: { error: false, message: "" },
    fullName: { error: false, message: "" },
    displayName: { error: false, message: "" },
    nationalId: { error: false, message: "" },
    phone: { error: false, message: "" },
    point: { error: false, message: "" },
  });

  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");

  const DEFAULT_PROFILE_IMAGE =
    "https://firebasestorage.googleapis.com/v0/b/notiline-vtoisn.appspot.com/o/user.png?alt=media&token=470b8374-53a9-40c6-8013-68a466566fef";

  const formatPhoneNumber = (value, country) => {
    try {
      const formatter = new AsYouType(country.code);
      let formatted = formatter.input(value);

      if (!formatted.startsWith(country.phoneCode)) {
        formatted = formatted.replace(/^\D+/g, "");
        formatted = `${country.phoneCode} ${formatted}`;
      }

      return formatted;
    } catch (e) {
      return value;
    }
  };

  const handleChange = (field) => (event) => {
    let value = event.target.value;

    if (field === "phone") {
      value = formatPhoneNumber(value, selectedCountry);
    }

    if (field === "nationalId") {
      value = value.toUpperCase();
    }

    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }));

    setValidations((prev) => ({
      ...prev,
      [field]: { error: false, message: "" },
    }));

    if (error) setError("");
  };

  const handleCountryChange = (event, newValue) => {
    if (newValue) {
      setSelectedCountry(newValue);
      if (formData.phone) {
        const phoneWithoutCode = formData.phone.replace(/^\+\d+\s*/, "");
        const newPhone = formatPhoneNumber(phoneWithoutCode, newValue);
        setFormData((prev) => ({
          ...prev,
          phone: newPhone,
          nationalId: "", // Reset ID when country changes
        }));
      }
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!email || email.trim() === "") {
      return { error: true, message: "El email es requerido" };
    }
    if (!emailRegex.test(email)) {
      return { error: true, message: "Formato de email inválido" };
    }
    return { error: false, message: "" };
  };

  const validateFullName = (fullName) => {
    if (!fullName || fullName.trim() === "") {
      return { error: true, message: "El nombre completo es requerido" };
    }
    if (fullName.trim().length < 5) {
      return {
        error: true,
        message: "El nombre completo debe tener al menos 5 caracteres",
      };
    }
    if (!/^[A-Za-záéíóúñÁÉÍÓÚÑ\s]+$/.test(fullName)) {
      return {
        error: true,
        message: "El nombre solo puede contener letras y espacios",
      };
    }
    return { error: false, message: "" };
  };

  const validateDisplayName = (displayName) => {
    if (!displayName || displayName.trim() === "") {
      return { error: true, message: "El nombre de usuario es requerido" };
    }
    if (displayName.trim().length < 3) {
      return {
        error: true,
        message: "El nombre de usuario debe tener al menos 3 caracteres",
      };
    }
    if (!/^[A-Za-z0-9_]+$/.test(displayName)) {
      return {
        error: true,
        message: "Solo letras, números y guiones bajos",
      };
    }
    return { error: false, message: "" };
  };

  const validateNationalId = (nationalId, country) => {
    const idConfig = idPatterns[country.code] || idPatterns.DEFAULT;

    if (!nationalId || nationalId.trim() === "") {
      return { error: true, message: `El ${idConfig.name} es requerido` };
    }

    if (!idConfig.pattern.test(nationalId)) {
      return { error: true, message: idConfig.message };
    }

    // Additional country-specific validations
    if (country.code === "CL") {
      // Chilean RUT validation
      const rutDigits = nationalId.slice(0, -2);
      const verifier = nationalId.slice(-1).toLowerCase();
      const calculatedVerifier = calculateChileanRutVerifier(rutDigits);
      if (verifier !== calculatedVerifier) {
        return {
          error: true,
          message: "RUT inválido (dígito verificador incorrecto)",
        };
      }
    }
    // Add more country-specific validations as needed

    return { error: false, message: "" };
  };

  // Helper function for Chilean RUT validation
  const calculateChileanRutVerifier = (rutDigits) => {
    const digits = rutDigits.split("").map(Number).reverse();
    let multiplier = 2;
    let sum = 0;

    for (const digit of digits) {
      sum += digit * multiplier;
      multiplier = multiplier === 7 ? 2 : multiplier + 1;
    }

    const remainder = sum % 11;
    const verifier = 11 - remainder;

    if (verifier === 11) return "0";
    if (verifier === 10) return "k";
    return String(verifier);
  };

  const validatePassword = (password) => {
    if (!password) {
      return { error: true, message: "La contraseña es requerida" };
    }
    if (password.length < 6) {
      return {
        error: true,
        message: "La contraseña debe tener al menos 6 caracteres",
      };
    }
    return { error: false, message: "" };
  };

  const validateConfirmPassword = (confirmPassword, password) => {
    if (!confirmPassword) {
      return { error: true, message: "Debe confirmar la contraseña" };
    }
    if (password !== confirmPassword) {
      return { error: true, message: "Las contraseñas no coinciden" };
    }
    return { error: false, message: "" };
  };

  const validatePhone = (phoneNumber, country) => {
    if (!phoneNumber.trim()) {
      return { error: true, message: "El número de teléfono es requerido" };
    }

    try {
      const fullNumber = phoneNumber.startsWith("+")
        ? phoneNumber
        : `${country.phoneCode}${phoneNumber.replace(/^\D+/g, "")}`;

      const parsedNumber = parsePhoneNumber(fullNumber, country.code);

      if (!parsedNumber.isValid()) {
        return { error: true, message: `Número inválido para ${country.name}` };
      }

      return { error: false, message: "" };
    } catch (error) {
      return { error: true, message: "Formato de número inválido" };
    }
  };

  const validateForm = () => {
    const newValidations = {
      email: validateEmail(formData.email),
      password: validatePassword(formData.password),
      confirmPassword: validateConfirmPassword(
        formData.confirmPassword,
        formData.password
      ),
      fullName: validateFullName(formData.fullName),
      displayName: validateDisplayName(formData.displayName),
      nationalId: validateNationalId(formData.nationalId, selectedCountry),
      point: validatePoint(formData.point),
      phone: validatePhone(formData.phone, selectedCountry), // Ahora devuelve el mismo formato
    };

    setValidations(newValidations);
    return !Object.values(newValidations).some(
      (validation) => validation.error
    );
  };

  const validatePoint = (point) => {
    if (!point || point.trim() === "") {
      return { error: true, message: "La localidad es requerida" };
    }
    return { error: false, message: "" };
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateForm()) return;

    setIsLoading(true);
    try {
      const fullNumber = formData.phone.startsWith("+")
        ? formData.phone
        : `${selectedCountry.phoneCode}${formData.phone.replace(/^\D+/g, "")}`;

      await createUser(
        formData.email,
        formData.password,
        formData.displayName,
        formData.fullName,
        formData.nationalId,
        fullNumber,
        formData.point
      );

      navigate("/subscription");
    } catch (error) {
      setError(
        error.code === "auth/email-already-in-use"
          ? "Este email ya está registrado"
          : error.message
      );
    } finally {
      setIsLoading(false);
    }
  };

  const getIdLabel = () => {
    const idConfig = idPatterns[selectedCountry.code] || idPatterns.DEFAULT;
    return idConfig.name;
  };

  return (
    <Container maxWidth="xs">
      <Card elevation={3} sx={{ mt: 4, mb: 4, borderRadius: 2 }}>
        <CardContent>
          <Stack spacing={2} alignItems="center">
            <CardMedia
              component="img"
              image={logoSvg}
              title="Notiline"
              sx={{ width: 180, height: "auto", mt: 2 }}
            />

            <Typography
              variant="h5"
              component="h1"
              textAlign="center"
              fontWeight="300"
              gutterBottom
            >
              Notiline
            </Typography>

            <Typography variant="h5" component="h1" fontWeight="500">
              Registrarse
            </Typography>

            {error && (
              <Alert severity="error" sx={{ width: "100%", borderRadius: 1 }}>
                {error}
              </Alert>
            )}

            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ width: "100%" }}
            >
              <TextField
                fullWidth
                margin="normal"
                id="email"
                label="Email"
                type="email"
                value={formData.email}
                onChange={handleChange("email")}
                error={validations.email.error}
                helperText={validations.email.message}
                disabled={isLoading}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailIcon />
                    </InputAdornment>
                  ),
                }}
                autoComplete="email"
              />

              <TextField
                fullWidth
                margin="normal"
                id="displayName"
                label="Nombre de Usuario"
                value={formData.displayName}
                onChange={handleChange("displayName")}
                error={validations.displayName.error}
                helperText={validations.displayName.message}
                disabled={isLoading}
                InputProps={{
                  startstartAdornment: (
                    <InputAdornment position="start">
                      <PersonIcon />
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                fullWidth
                margin="normal"
                id="fullName"
                label="Nombre Completo"
                value={formData.fullName}
                onChange={handleChange("fullName")}
                error={validations.fullName.error}
                helperText={validations.fullName.message}
                disabled={isLoading}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonIcon />
                    </InputAdornment>
                  ),
                }}
                autoComplete="name"
              />

              <Autocomplete
                options={countriesData}
                value={selectedCountry}
                onChange={handleCountryChange}
                getOptionLabel={(option) =>
                  `${option.flag} ${option.name} (${option.phoneCode})`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="País"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                  />
                )}
                isOptionEqualToValue={(option, value) =>
                  option.code === value.code
                }
                disabled={isLoading}
              />

              <TextField
                fullWidth
                margin="normal"
                id="nationalId"
                label={getIdLabel()}
                value={formData.nationalId}
                onChange={handleChange("nationalId")}
                error={validations.nationalId.error}
                helperText={validations.nationalId.message}
                disabled={isLoading}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <BadgeIcon />
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                fullWidth
                margin="normal"
                id="phone"
                label="Teléfono"
                value={formData.phone}
                onChange={handleChange("phone")}
                error={validations.phone.error}
                helperText={
                  validations.phone.message ||
                  `Formato para ${selectedCountry.name}: ${selectedCountry.phoneCode}`
                }
                disabled={isLoading}
                placeholder={`${selectedCountry.phoneCode} XXXXXXXXX`}
              />

              <TextField
                fullWidth
                margin="normal"
                id="point"
                label="Localidad"
                value={formData.point}
                onChange={handleChange("point")}
                error={validations.point.error}
                helperText={validations.point.message}
                disabled={isLoading}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationIcon />
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                fullWidth
                margin="normal"
                id="password"
                label="Contraseña"
                type={showPassword ? "text" : "password"}
                value={formData.password}
                onChange={handleChange("password")}
                error={validations.password.error}
                helperText={validations.password.message}
                disabled={isLoading}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                        disabled={isLoading}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                autoComplete="new-password"
              />

              <TextField
                fullWidth
                margin="normal"
                id="confirmPassword"
                label="Confirmar Contraseña"
                type={showPassword ? "text" : "password"}
                value={formData.confirmPassword}
                onChange={handleChange("confirmPassword")}
                error={validations.confirmPassword.error}
                helperText={validations.confirmPassword.message}
                disabled={isLoading}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                        disabled={isLoading}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                autoComplete="new-password"
              />

              <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={isLoading}
                sx={{
                  mt: 3,
                  mb: 2,
                  height: 42,
                  textTransform: "none",
                  fontSize: "1rem",
                }}
              >
                {isLoading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Registrarse"
                )}
              </Button>

              <Box textAlign="center" sx={{ mt: 2, mb: 2 }}>
                ¿Ya tienes cuenta?{" "}
                <Link
                  href="/login"
                  sx={{
                    textDecoration: "none",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  }}
                >
                  Iniciar sesión
                </Link>
              </Box>
            </Box>
          </Stack>
        </CardContent>
      </Card>
    </Container>
  );
};

export default Register;