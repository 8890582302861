import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  Container,
  AppBar,
  Toolbar,
  ListItem,
  CircularProgress,
  List,
  Avatar,
  IconButton,
} from "@mui/material";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import { auth, db } from "../firebase";
import {
  collection,
  query,
  where,
  orderBy,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import BottomToolbar from "./BottomToolbar";
import NotificationsIcon from "@mui/icons-material/Notifications";
import EmailIcon from "@mui/icons-material/Email";
import EventIcon from "@mui/icons-material/Event";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";

const Notifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const user = auth.currentUser;
    if (!user) {
      console.error("No user is currently authenticated");
      setLoading(false);
      return;
    }

    const notificationsRef = collection(db, "notifications");
    const userNotificationsQuery = query(
      notificationsRef,
      where("userId", "==", user.uid),
      orderBy("timestamp", "desc")
    );

    const unsubscribe = onSnapshot(userNotificationsQuery, (snapshot) => {
      const notificationsData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setNotifications(notificationsData);
      setLoading(false);

      // Marcar todas las notificaciones como leídas
      snapshot.docs.forEach((doc) => {
        if (!doc.data().read) {
          updateDoc(doc.ref, { read: true });
        }
      });
    });

    return () => unsubscribe();
  }, []);

  const formatDate = (timestamp) => {
    if (timestamp && timestamp.toDate) {
      const date = timestamp.toDate();
      return date.toLocaleDateString("es-ES", {
        year: "numeric",
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });
    }
    return "";
  };

  const handleNotificationClick = (notification) => {
    if (notification.type === "message") {
      navigate("/chats");
    } else if (notification.type === "offer" || notification.type === "event") {
      navigate(`/perfil/${notification.companyId}`);
    }
  };

  const getNotificationIcon = (type) => {
    switch (type) {
      case "message":
        return <EmailIcon sx={styles.icon} />;
      case "offer":
        return <LocalOfferIcon sx={styles.icon} />;
      case "event":
        return <EventIcon sx={styles.icon} />;
      default:
        return <NotificationsIcon sx={styles.icon} />;
    }
  };

  const getNotificationColor = (type) => {
    switch (type) {
      case "message":
        return "#4CAF50";
      case "offer":
        return "#2196F3";
      case "event":
        return "#FF9800";
      default:
        return "#9E9E9E";
    }
  };

  const renderNotificationItem = (notification) => {
    const notificationColor = getNotificationColor(notification.type);
    return (
      <ListItem
        key={notification.id}
        sx={{
          ...styles.listItem,
          borderLeft: `4px solid ${notificationColor}`,
        }}
        onClick={() => handleNotificationClick(notification)}
      >
        <Box sx={styles.avatarContainer}>
          <Avatar sx={{ bgcolor: notificationColor }}>
            {getNotificationIcon(notification.type)}
          </Avatar>
        </Box>
        <Box sx={styles.contentContainer}>
          <Typography variant="subtitle1" sx={styles.notificationType}>
            {notification.type === "message"
              ? "Nuevo mensaje"
              : notification.type === "offer"
              ? "Nueva oferta"
              : notification.type === "event"
              ? "Nuevo evento"
              : "Notificación"}
          </Typography>
          <Typography variant="body2" sx={styles.notificationMessage}>
            {notification.message}
          </Typography>
          <Typography variant="caption" sx={styles.notificationTimestamp}>
            {formatDate(notification.timestamp)}
          </Typography>
        </Box>
      </ListItem>
    );
  };

  const styles = {
    appBar: {
      bgcolor: "#312783",
    },
    title: {
      flexGrow: 1,
      textAlign: "center",
      fontWeight: "bold",
    },
    container: {
      mt: 10,
      mb: 10,
      px: 2,
    },
    listItem: {
      display: "flex",
      alignItems: "flex-start",
      padding: 2,
      mb: 2,
      borderRadius: 2,
      boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
      transition: "all 0.3s ease",
      cursor: "pointer",
      "&:hover": {
        transform: "translateY(-2px)",
        boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
      },
    },
    avatarContainer: {
      mr: 2,
    },
    contentContainer: {
      flexGrow: 1,
    },
    notificationType: {
      fontWeight: "bold",
      color: "#312783",
    },
    notificationMessage: {
      color: "text.secondary",
      mb: 1,
    },
    notificationTimestamp: {
      color: "text.disabled",
    },
    icon: {
      color: "white",
    },
    loadingContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "50vh",
    },
    noNotificationsText: {
      textAlign: "center",
      mt: 4,
      color: "text.secondary",
    },
  };

  return (
    <Box>
      <AppBar position="fixed" sx={styles.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={() => navigate(-1)}>
            <ChevronLeftRoundedIcon />
          </IconButton>
          <Typography variant="h6" sx={styles.title}>
            Notificaciones
          </Typography>
        </Toolbar>
      </AppBar>
      <Container sx={styles.container}>
        {loading ? (
          <Box sx={styles.loadingContainer}>
            <CircularProgress />
          </Box>
        ) : notifications.length > 0 ? (
          <List>{notifications.map(renderNotificationItem)}</List>
        ) : (
          <Typography variant="body1" sx={styles.noNotificationsText}>
            No tienes notificaciones.
          </Typography>
        )}
      </Container>
      <BottomToolbar />
    </Box>
  );
};

export default Notifications;
