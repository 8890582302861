import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  doc,
  getDoc,
  collection,
  query,
  where,
  onSnapshot,
  addDoc,
  updateDoc,
  serverTimestamp,
  orderBy,
  deleteDoc,
  getDocs,
} from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db, auth, storage } from "../firebase";
import {
  Container,
  Box,
  Avatar,
  Typography,
  Button,
  Grid,
  Card,
  CardContent,
  CardActions,
  CardHeader,
  Divider,
  IconButton,
  CircularProgress,
  Tab,
  Tabs,
  TextField,
  useTheme,
  useMediaQuery,
  Fab,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import {
  Email as EmailIcon,
  Phone as PhoneIcon,
  LocationOn as LocationIcon,
  ArrowBack as ArrowBackIcon,
  Edit as EditIcon,
  Add as AddIcon,
  Work as WorkIcon,
  School as SchoolIcon,
  Favorite as FavoriteIcon,
  Language as LanguageIcon,
  InsertEmoticon as EmojiIcon,
  Image as ImageIcon,
  Close as CloseIcon,
  Person as PersonIcon,
  Circle as CircleIcon,
  MoreVert as MoreVertIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";

const UserProfile = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [user, setUser] = useState(null);
  const [states, setStates] = useState([]);
  const [activities, setActivities] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedStateImage, setSelectedStateImage] = useState(null);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const fileInputRef = useRef(null);
  const stateFileInputRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [tabValue, setTabValue] = useState(0);
  const [isCurrentUser, setIsCurrentUser] = useState(false);
  const [newStateText, setNewStateText] = useState("");
  const [openStateDialog, setOpenStateDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [editFormData, setEditFormData] = useState({
    displayName: "",
    phone: "",
    point: "",
    professional: "",
    education: "",
    interests: "",
    profileImageUrl: "",
  });

  const reactions = ["👍", "❤️", "😊", "😮", "😢", "😡"];

  // Efecto para cargar usuario
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const userDoc = await getDoc(doc(db, "users", userId));
        if (userDoc.exists()) {
          setUser({ id: userDoc.id, ...userDoc.data() });
          setIsCurrentUser(auth.currentUser?.uid === userId);

          setEditFormData({
            displayName: userDoc.data().displayName || "",
            phone: userDoc.data().phone || "",
            point: userDoc.data().point || "",
            professional: userDoc.data().professional || "",
            education: userDoc.data().education || "",
            interests: userDoc.data().interests || "",
            profileImageUrl: userDoc.data().profileImageUrl || "",
          });
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching user:", error);
        setLoading(false);
      }
    };

    fetchUser();
  }, [userId]);

  // Efecto para estados
  useEffect(() => {
    const statesRef = collection(db, "states");
    const userStatesQuery = query(
      statesRef,
      where("userId", "==", userId),
      orderBy("createdAt", "desc")
    );

    const unsubscribe = onSnapshot(userStatesQuery, (snapshot) => {
      const statesData = [];
      snapshot.forEach((doc) => {
        statesData.push({
          id: doc.id,
          ...doc.data(),
          reactions: doc.data().reactions || {},
        });
      });
      setStates(statesData);
    });

    return () => unsubscribe();
  }, [userId]);

  // Efecto para actividades
  useEffect(() => {
    const activitiesRef = collection(db, "activities");
    const userActivitiesQuery = query(
      activitiesRef,
      where("userId", "==", userId),
      orderBy("timestamp", "desc")
    );

    const unsubscribe = onSnapshot(userActivitiesQuery, (snapshot) => {
      const activitiesData = [];
      snapshot.forEach((doc) => {
        activitiesData.push({ id: doc.id, ...doc.data() });
      });
      setActivities(activitiesData);
    });

    return () => unsubscribe();
  }, [userId]);

  const handleImageUpload = async (file) => {
    if (!file) return null;
    const storageRef = ref(
      storage,
      `profile-images/${auth.currentUser.uid}/${Date.now()}_${file.name}`
    );

    try {
      const snapshot = await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(snapshot.ref);
      return downloadURL;
    } catch (error) {
      console.error("Error uploading image:", error);
      return null;
    }
  };

  const handleStateImageUpload = async (file) => {
    if (!file) return null;
    const storageRef = ref(
      storage,
      `state-images/${auth.currentUser.uid}/${Date.now()}_${file.name}`
    );

    try {
      const snapshot = await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(snapshot.ref);
      return downloadURL;
    } catch (error) {
      console.error("Error uploading state image:", error);
      return null;
    }
  };

  const handleCreateState = async () => {
    if ((!newStateText.trim() && !selectedStateImage) || !auth.currentUser)
      return;

    try {
      const currentUser = auth.currentUser;
      let imageUrl = null;

      if (selectedStateImage) {
        imageUrl = await handleStateImageUpload(selectedStateImage);
      }

      const stateData = {
        userId: currentUser.uid,
        content: newStateText,
        imageUrl: imageUrl,
        createdAt: serverTimestamp(),
        reactions: {},
        userDisplayName: currentUser.displayName || "Usuario",
        userProfileImage: currentUser.photoURL || "URL_de_imagen_por_defecto",
      };

      const stateRef = await addDoc(collection(db, "states"), stateData);

      await addDoc(collection(db, "activities"), {
        userId: currentUser.uid,
        type: "post",
        content: "Creó una nueva publicación",
        timestamp: serverTimestamp(),
        reference: stateRef.id,
      });

      setNewStateText("");
      setSelectedStateImage(null);
      setOpenStateDialog(false);
    } catch (error) {
      console.error("Error creating state:", error);
    }
  };

  const handleUpdateProfile = async () => {
    try {
      const userRef = doc(db, "users", userId);
      let imageUrl = editFormData.profileImageUrl;

      if (selectedImage) {
        const uploadedUrl = await handleImageUpload(selectedImage);
        if (uploadedUrl) {
          imageUrl = uploadedUrl;
        }
      }

      await updateDoc(userRef, {
        ...editFormData,
        profileImageUrl: imageUrl,
        updatedAt: serverTimestamp(),
      });

      await addDoc(collection(db, "activities"), {
        userId: userId,
        type: "profile_update",
        content: "Actualizó su perfil",
        timestamp: serverTimestamp(),
      });

      setUser((prev) => ({
        ...prev,
        ...editFormData,
        profileImageUrl: imageUrl,
      }));

      setSelectedImage(null);
      setOpenEditDialog(false);
    } catch (error) {
      console.error("Error updating profile:", error);
    }
  };

  const handleReaction = async (stateId, reaction) => {
    const currentUserId = auth.currentUser.uid;
    const stateRef = doc(db, "states", stateId);

    try {
      const stateDoc = await getDoc(stateRef);
      const currentReactions = stateDoc.data().reactions || {};

      if (currentReactions[currentUserId] === reaction) {
        delete currentReactions[currentUserId];
      } else {
        currentReactions[currentUserId] = reaction;
      }

      await updateDoc(stateRef, { reactions: currentReactions });
    } catch (error) {
      console.error("Error updating reaction:", error);
    }
  };

  const getReactionCount = (reactions, emoji) => {
    return Object.values(reactions).filter((reaction) => reaction === emoji)
      .length;
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleDeleteState = async (stateId) => {
    try {
      await deleteDoc(doc(db, "states", stateId));
      // También eliminar la actividad relacionada si existe
      const relatedActivities = await getDocs(
        query(collection(db, "activities"), where("reference", "==", stateId))
      );
      relatedActivities.forEach(async (doc) => {
        await deleteDoc(doc.ref);
      });
    } catch (error) {
      console.error("Error deleting state:", error);
    }
  };

  const handleDeleteActivity = async (activityId) => {
    try {
      await deleteDoc(doc(db, "activities", activityId));
    } catch (error) {
      console.error("Error deleting activity:", error);
    }
  };

  // Componente ActivityItem
  const ActivityItem = ({ activity }) => {
    const getActivityIcon = (type) => {
      switch (type) {
        case "post":
          return <EditIcon color="primary" />;
        case "profile_update":
          return <PersonIcon color="secondary" />;
        default:
          return <CircleIcon />;
      }
    };

    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          mb: 2,
          gap: 2,
          position: "relative",
          "&:hover .delete-button": {
            opacity: 1,
          },
        }}
      >
        {getActivityIcon(activity.type)}
        <Box flex={1}>
          <Typography variant="body1">{activity.content}</Typography>
          <Typography variant="caption" color="textSecondary">
            {activity.timestamp?.seconds
              ? new Date(activity.timestamp.seconds * 1000).toLocaleString()
              : "Ahora"}
          </Typography>
        </Box>
        {isCurrentUser && (
          <IconButton
            className="delete-button"
            size="small"
            onClick={() => handleDeleteActivity(activity.id)}
            sx={{
              opacity: 0,
              transition: "opacity 0.2s",
              "&:hover": {
                color: "error.main",
              },
            }}
          >
            <DeleteIcon fontSize="small" />
          </IconButton>
        )}
      </Box>
    );
  };

  // Componente StateCard
  const StateCard = ({ state }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const openReactions = Boolean(anchorEl);
    const openMenu = Boolean(menuAnchorEl);

    return (
      <Card sx={{ mb: 2 }}>
        <CardHeader
          avatar={
            <Avatar
              src={user.profileImageUrl || ""}
              sx={{
                width: 48,
                height: 48,
                border: 2,
                borderColor: "primary.main",
                boxShadow: "0 0 10px rgba(0,0,0,0.1)",
              }}
            >
              {state.userDisplayName?.[0]}
            </Avatar>
          }
          title={state.userDisplayName}
          action={
            isCurrentUser &&
            state.userId === auth.currentUser?.uid && (
              <>
                <IconButton
                  aria-label="settings"
                  onClick={(e) => setMenuAnchorEl(e.currentTarget)}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  anchorEl={menuAnchorEl}
                  open={openMenu}
                  onClose={() => setMenuAnchorEl(null)}
                >
                  <MenuItem
                    onClick={() => {
                      handleDeleteState(state.id);
                      setMenuAnchorEl(null);
                    }}
                    sx={{ color: "error.main" }}
                  >
                    <DeleteIcon fontSize="small" sx={{ mr: 1 }} />
                    Eliminar
                  </MenuItem>
                </Menu>
              </>
            )
          }
          subheader={
            state.createdAt?.seconds
              ? new Date(state.createdAt.seconds * 1000).toLocaleString()
              : "Ahora"
          }
        />
        <CardContent>
          <Typography variant="body1">{state.content}</Typography>
          {state.imageUrl && (
            <Box sx={{ mt: 2 }}>
              <img
                src={state.imageUrl}
                alt="State content"
                style={{
                  maxWidth: "100%",
                  borderRadius: "8px",
                  boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                }}
              />
            </Box>
          )}
        </CardContent>
        <CardActions disableSpacing>
          <IconButton
            onClick={(event) => setAnchorEl(event.currentTarget)}
            aria-label="add reaction"
          >
            <EmojiIcon />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={openReactions}
            onClose={() => setAnchorEl(null)}
          >
            <Box sx={{ display: "flex", p: 1 }}>
              {reactions.map((emoji) => (
                <MenuItem
                  key={emoji}
                  onClick={() => {
                    handleReaction(state.id, emoji);
                    setAnchorEl(null);
                  }}
                >
                  {emoji}
                </MenuItem>
              ))}
            </Box>
          </Menu>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              ml: 1,
            }}
          >
            {reactions.map((emoji) => {
              const count = getReactionCount(state.reactions, emoji);
              if (count > 0) {
                return (
                  <Typography key={emoji} variant="body2" sx={{ mr: 1 }}>
                    {emoji} {count}
                  </Typography>
                );
              }
              return null;
            })}
          </Box>
        </CardActions>
      </Card>
    );
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!user) {
    return (
      <Container>
        <Typography variant="h6">Usuario no encontrado</Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="md">
      <Box sx={{ py: 2 }}>
        <Box sx={{ mb: 2, display: "flex", alignItems: "center" }}>
          <IconButton onClick={() => navigate(-1)} sx={{ mr: 1 }}>
            <ArrowBackIcon />
          </IconButton>
          <Typography variant={isMobile ? "h6" : "h5"}>
            Perfil de Usuario
          </Typography>
        </Box>

        <Card sx={{ mb: 3 }}>
          <CardContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: isMobile ? "column" : "row",
                alignItems: isMobile ? "center" : "flex-start",
                mb: 3,
              }}
            >
              <Avatar
                src={user.profileImageUrl}
                sx={{
                  width: isMobile ? 100 : 120,
                  height: isMobile ? 100 : 120,
                  mb: isMobile ? 2 : 0,
                  mr: isMobile ? 0 : 3,
                  border: 3,
                  borderColor: "primary.main",
                  boxShadow: "0 0 10px rgba(0,0,0,0.2)",
                }}
              />
              <Box sx={{ textAlign: isMobile ? "center" : "left" }}>
                <Typography
                  variant={isMobile ? "h5" : "h4"}
                  sx={{ wordBreak: "break-word" }}
                >
                  {user.displayName}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {user.email}
                </Typography>
                {isCurrentUser && (
                  <Button
                    variant="outlined"
                    startIcon={<EditIcon />}
                    onClick={() => {
                      setEditFormData({
                        displayName: user.displayName || "",
                        phone: user.phone || "",
                        point: user.point || "",
                        professional: user.professional || "",
                        education: user.education || "",
                        interests: user.interests || "",
                        profileImageUrl: user.profileImageUrl || "",
                      });
                      setOpenEditDialog(true);
                    }}
                    sx={{ mt: 1 }}
                    size={isMobile ? "small" : "medium"}
                  >
                    Editar Perfil
                  </Button>
                )}
              </Box>
            </Box>

            <Divider sx={{ my: 2 }} />

            <Grid container spacing={2}>
              <Grid item xs={12} sm={4}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <LocationIcon sx={{ mr: 1 }} color="action" />
                  <Typography variant="body2">{user.point}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <PhoneIcon sx={{ mr: 1 }} color="action" />
                  <Typography variant="body2">{user.phone}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <EmailIcon sx={{ mr: 1 }} color="action" />
                  <Typography variant="body2">{user.email}</Typography>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            variant={isMobile ? "fullWidth" : "standard"}
          >
            <Tab label="Publicaciones" />
            <Tab label="Información" />
            <Tab label="Actividad" />
          </Tabs>
        </Box>

        {tabValue === 0 && (
          <Box sx={{ py: 3 }}>
            <Card sx={{ mb: 3 }}>
              <CardContent>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Avatar
                    src={user.profileImageUrl || ""}
                    sx={{
                      width: 40,
                      height: 40,
                      mr: 2,
                      border: 2,
                      borderColor: "primary.main",
                      boxShadow: "0 0 10px rgba(0,0,0,0.1)",
                    }}
                  />
                  <Button
                    fullWidth
                    variant="outlined"
                    onClick={() => setOpenStateDialog(true)}
                    startIcon={<EditIcon />}
                    sx={{ justifyContent: "left", textAlign: "left" }}
                  >
                    ¿Qué estás pensando?
                  </Button>
                </Box>
              </CardContent>
            </Card>

            {states.map((state) => (
              <StateCard key={state.id} state={state} />
            ))}
          </Box>
        )}

        {tabValue === 1 && (
          <Box sx={{ py: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <Typography
                      variant="h6"
                      sx={{ fontSize: isMobile ? "1rem" : "1.25rem", mb: 2 }}
                    >
                      <WorkIcon sx={{ mr: 1, verticalAlign: "middle" }} />
                      Información Profesional
                    </Typography>
                    <Typography variant="body2" paragraph>
                      {user.professional ||
                        "No hay información profesional disponible"}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <Typography
                      variant="h6"
                      sx={{ fontSize: isMobile ? "1rem" : "1.25rem", mb: 2 }}
                    >
                      <SchoolIcon sx={{ mr: 1, verticalAlign: "middle" }} />
                      Educación
                    </Typography>
                    <Typography variant="body2" paragraph>
                      {user.education ||
                        "No hay información educativa disponible"}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card>
                  <CardContent>
                    <Typography
                      variant="h6"
                      sx={{ fontSize: isMobile ? "1rem" : "1.25rem", mb: 2 }}
                    >
                      <FavoriteIcon sx={{ mr: 1, verticalAlign: "middle" }} />
                      Intereses
                    </Typography>
                    <Typography variant="body2" paragraph>
                      {user.interests || "No hay intereses listados"}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        )}

        {tabValue === 2 && (
          <Box sx={{ py: 3 }}>
            <Card>
              <CardContent>
                <Typography
                  variant="h6"
                  sx={{ fontSize: isMobile ? "1rem" : "1.25rem", mb: 2 }}
                >
                  <LanguageIcon sx={{ mr: 1, verticalAlign: "middle" }} />
                  Actividad Reciente
                </Typography>
                {activities.length > 0 ? (
                  activities.map((activity) => (
                    <ActivityItem key={activity.id} activity={activity} />
                  ))
                ) : (
                  <Typography variant="body2" color="textSecondary">
                    No hay actividad reciente para mostrar
                  </Typography>
                )}
              </CardContent>
            </Card>
          </Box>
        )}
      </Box>

      {/* Diálogo de edición de perfil */}
      <Dialog
        open={openEditDialog}
        onClose={() => {
          setOpenEditDialog(false);
          setSelectedImage(null);
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Editar Perfil</DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2, display: "flex", flexDirection: "column", gap: 2 }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <Avatar
                src={
                  selectedImage
                    ? URL.createObjectURL(selectedImage)
                    : editFormData.profileImageUrl
                }
                sx={{
                  width: 80,
                  height: 80,
                  border: 2,
                  borderColor: "primary.main",
                  boxShadow: "0 0 10px rgba(0,0,0,0.1)",
                }}
              />
              <Button
                variant="outlined"
                onClick={() => fileInputRef.current?.click()}
                startIcon={<ImageIcon />}
              >
                Subir imagen
              </Button>
              <input
                type="file"
                ref={fileInputRef}
                hidden
                accept="image/*"
                onChange={(e) => setSelectedImage(e.target.files[0])}
              />
            </Box>
            <TextField
              label="Nombre de usuario"
              fullWidth
              value={editFormData.displayName}
              onChange={(e) =>
                setEditFormData({
                  ...editFormData,
                  displayName: e.target.value,
                })
              }
            />
            <TextField
              label="Teléfono"
              fullWidth
              value={editFormData.phone}
              onChange={(e) =>
                setEditFormData({ ...editFormData, phone: e.target.value })
              }
            />
            <TextField
              label="Ubicación"
              fullWidth
              value={editFormData.point}
              onChange={(e) =>
                setEditFormData({ ...editFormData, point: e.target.value })
              }
            />
            <TextField
              label="Información Profesional"
              fullWidth
              multiline
              rows={3}
              value={editFormData.professional}
              onChange={(e) =>
                setEditFormData({
                  ...editFormData,
                  professional: e.target.value,
                })
              }
            />
            <TextField
              label="Educación"
              fullWidth
              multiline
              rows={3}
              value={editFormData.education}
              onChange={(e) =>
                setEditFormData({ ...editFormData, education: e.target.value })
              }
            />
            <TextField
              label="Intereses"
              fullWidth
              multiline
              rows={3}
              value={editFormData.interests}
              onChange={(e) =>
                setEditFormData({ ...editFormData, interests: e.target.value })
              }
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenEditDialog(false);
              setSelectedImage(null);
            }}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleUpdateProfile}
            variant="contained"
            disabled={!editFormData.displayName.trim()}
          >
            Guardar Cambios
          </Button>
        </DialogActions>
      </Dialog>

      {/* Diálogo de creación de estado */}
      <Dialog
        open={openStateDialog}
        onClose={() => {
          setOpenStateDialog(false);
          setSelectedStateImage(null);
          setShowEmojiPicker(false);
          setNewStateText("");
        }}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Crear publicación</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            multiline
            rows={4}
            fullWidth
            placeholder="¿Qué estás pensando?"
            value={newStateText}
            onChange={(e) => setNewStateText(e.target.value)}
            variant="outlined"
            sx={{ mt: 2 }}
          />
          {selectedStateImage && (
            <Box sx={{ mt: 2, position: "relative" }}>
              <img
                src={URL.createObjectURL(selectedStateImage)}
                alt="Preview"
                style={{
                  maxWidth: "100%",
                  maxHeight: 200,
                  objectFit: "cover",
                  borderRadius: "8px",
                }}
              />
              <IconButton
                sx={{
                  position: "absolute",
                  top: 8,
                  right: 8,
                  bgcolor: "background.paper",
                  "&:hover": { bgcolor: "background.paper" },
                }}
                onClick={() => setSelectedStateImage(null)}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          )}
          <Box sx={{ mt: 2, display: "flex", gap: 1 }}>
            <input
              type="file"
              ref={stateFileInputRef}
              hidden
              accept="image/*"
              onChange={(e) => setSelectedStateImage(e.target.files[0])}
            />
            <Button
              startIcon={<ImageIcon />}
              variant="outlined"
              onClick={() => stateFileInputRef.current?.click()}
            >
              Foto
            </Button>
            <Button
              startIcon={<EmojiIcon />}
              variant="outlined"
              onClick={() => setShowEmojiPicker(!showEmojiPicker)}
            >
              Emoji
            </Button>
          </Box>
          {showEmojiPicker && (
            <Box sx={{ mt: 2 }}>
              <Grid container spacing={1}>
                {["😊", "😂", "😍", "👍", "❤️", "😎", "🎉", "🌟"].map(
                  (emoji) => (
                    <Grid item key={emoji}>
                      <Button
                        onClick={() => {
                          setNewStateText((prev) => prev + emoji);
                        }}
                      >
                        {emoji}
                      </Button>
                    </Grid>
                  )
                )}
              </Grid>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenStateDialog(false);
              setSelectedStateImage(null);
              setShowEmojiPicker(false);
              setNewStateText("");
            }}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleCreateState}
            variant="contained"
            disabled={!newStateText.trim() && !selectedStateImage}
          >
            Publicar
          </Button>
        </DialogActions>
      </Dialog>

      {isCurrentUser && (
        <Fab
          color="primary"
          aria-label="add post"
          onClick={() => setOpenStateDialog(true)}
          sx={{
            position: "fixed",
            bottom: 16,
            right: 16,
          }}
        >
          <AddIcon />
        </Fab>
      )}
    </Container>
  );
};

export default UserProfile;